export default {
  data: {
    page: {
      theme: {
        logo: "red",
        type: "default"
      },
      title: "Contact",
      uri: "/contact/",
      slug: "contact",
      seo: {
        metaDesc: "",
        opengraphDescription: "",
        opengraphSiteName: "Vivid",
        opengraphTitle: "Contact - Vivid",
        opengraphType: "article",
        title: "Contact - Vivid",
      },
      flexchildren: [
        {
          __typename: "HeroSimple",
          title: "Contact",
          backgroundImage: {
            altText: "Header Image",
            localFile: {
              childImageSharp: {
                fluid: {
                  base64:
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABWklEQVQozz2R18pCQQyEzyMIVuy9N1CxXCg2FHsB3/89ls38s3PxXwxnd5J8SfZE6/Xabzabf+33e38+n/3tdvPv99t/v1//+Xz84/Hw9/tdfoiH3NVq5dvtts9msz6VSvlCoeCj7XaL3W6Hw+GA0+mEy+UCFoMwEAQCpXC+Xq/Km0wmyOVyyGQy+haLRfT7fSSTSUTP59MIsNfrZYRI4c5JLMQIUux4PNpoNLJms2m1Ws3y+bzOhNl4PLbpdGqxWMwiJmsaFmvSxWIBrobf7ycFfzaboV6vg+uBUBCme6/Xw2AwUDx4iUQC0XK5tPl8rg5hgk6nY0w0gtWZEHmNRkN+uVyWF2KVSsVCPeEWj8eN72hRt9vV/kyQWAwWg2uBxahWq/KGw6H8UqmEVqulOIHgj9DbpdNpEIiIQceOEt9EYkfHZIlQiQDFQn7w2cjx/RwbOMIcoY5A9wc1qtzayl80BgAAAABJRU5ErkJggg==",
                  aspectRatio: 2.7027027027027026,
                  src: "/static/192bbeeaf8c26fa6e0185d1524ad9fc1/ee604/Header-Image-6.png",
                  srcSet:
                    "/static/192bbeeaf8c26fa6e0185d1524ad9fc1/69585/Header-Image-6.png 200w,\n/static/192bbeeaf8c26fa6e0185d1524ad9fc1/497c6/Header-Image-6.png 400w,\n/static/192bbeeaf8c26fa6e0185d1524ad9fc1/ee604/Header-Image-6.png 800w,\n/static/192bbeeaf8c26fa6e0185d1524ad9fc1/f3583/Header-Image-6.png 1200w,\n/static/192bbeeaf8c26fa6e0185d1524ad9fc1/5707d/Header-Image-6.png 1600w,\n/static/192bbeeaf8c26fa6e0185d1524ad9fc1/0bd13/Header-Image-6.png 2884w",
                  sizes: "(max-width: 800px) 100vw, 800px",
                },
              },
            },
          },
        },
        {
          __typename: "ContactForm",
          formDescription:
            "We'd love to hear from you. Let us know what's on your mind and how we can help. ",
          successMessage: "Thanks for contacting us. We will get back to you shortly.",
          errorMessage:
            "Sorry, something went wrong. Please contact us directly at hello@vividcg.com",
          getInTouch: {
            address: "<b>Boulder Office</b><br />Vivid Consulting Group<br />\r\n1971 8th St.<br />\r\nBoulder, CO 80302\r\n<br /><br /><b>Seattle Office</b><br />Vivid Consulting Group<br />\r\n15301 SE 80th St.<br />\r\nNewcastle, WA 98059",
            emails: [
              {
                email: "hello@vividcg.com",
              },
            ],
            phone: "(303) 579-9648",
          },
        },
      ],
    },
  }
};
